<template>
  <el-card :body-style="{ padding: '10px' }">
    <el-row style="height: 30px" justify="space-between">
      <el-col :span="5">
        <el-radio-group v-model="radio1" @change="handleRadioChange">
          <el-radio-button :label="byID"></el-radio-button>
          <el-radio-button :label="byName"></el-radio-button>
          <el-radio-button :label="byIdOnOverseas"></el-radio-button>
        </el-radio-group>
      </el-col>
      <el-col :span="10">
        <el-form :model="listQuery" label-width="0px">
          <el-row>
            <el-col :span="8" v-if="radio1 != byName">
              <el-form-item prop="id">
                <el-input
                    style="width: 600px"
                    placeholder="厂商ID"
                    v-model.number="listQuery.id"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8" :offset="2">
              <el-form-item prop="name">
                <el-input
                    style="width: 600px"
                    placeholder="厂商名"
                    v-model="listQuery.name"
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="2">
              <el-form-item label-width="10px">
                <el-button style="width: 80px" @click="pushCurrentPage"
                >搜索
                </el-button
                >
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </el-col>
    </el-row>
  </el-card>
  <el-card>
    <el-table
        :data="tableData"
        max-height="700px"
        v-loading="loading"
        :key="key"
        @row-click="rowClick"
        :default-sort="defaultSort"
        @sort-change="sortChange"
    >
      <el-table-column prop="id" label="ID" v-if="radio1 != byName"/>
      <el-table-column prop="name" label="厂商"/>
      <el-table-column prop="total" label="总数" sortable="custom"/>
      <el-table-column
          prop="download"
          label="下载量"
          :formatter="downloadFormat"
          sortable="custom"
      />
      <el-table-column prop="tag" label="标签" v-if="radio1 != byName">
        <template v-slot="{ row }">
          <el-input
              class="input-text"
              v-model="row.tag"
              @blur="modifyManufacturerTag(row)"
          />
        </template>
      </el-table-column>
    </el-table>
  </el-card>
  <el-card :body-style="{ padding: '2px' }">
    <el-pagination
        v-model:currentPage="listQuery.page"
        :page-sizes="[1, 10, 20, 50, 100]"
        :page-size="listQuery.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="pageTotal"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
    >
    </el-pagination>
  </el-card>
</template>
<script>
import {getStatInfo, updateManufacturerTag} from "../api/stat.js";
import {deepCopy} from "../utils/util.js";

const defaultPage = 1;
const defaultSize = 100;
const byID = "按ID统计";
const byName = "按名字统计";
const byIdOnOverseas = "按ID统计海外数据";
const statCN = 1;
const statNotCN = 2;
const initListQuery = {
  id: undefined,
  by: "name",
  country: 1,
  page: defaultPage,
  pageSize: defaultSize,
  name: "",
};
export default {
  data() {
    return {
      byID,
      byName,
      byIdOnOverseas,
      radio1: byName,
      pageTotal: 0,
      loading: false,
      tableData: [],
      listQuery: deepCopy(initListQuery),
      defaultSort: {prop: "total", order: "descending"},
      key: "",
    };
  },
  created() {
    this.paramsParse();
    this.getList();
  },
  watch: {
    $route(to, from) {
      if (to.name == from.name) {
        this.paramsParse();
        this.getList();
      }
    },
  },
  methods: {
    getList() {
      this.loading = true;
      getStatInfo(this.listQuery)
          .then((resp) => {
            this.tableData = resp.data.data.records;
            this.pageTotal = resp.data.data.total;
            this.loading = false;
          })
          .catch((err) => {
            this.loading = false;
            console.log(err);
          });
    },
    paramsParse() {
      if (this.$route.query.listQuery) {
        this.listQuery = JSON.parse(this.$route.query.listQuery);
      } else {
        this.listQuery = deepCopy(initListQuery);
      }
      if (this.listQuery.by == "id") {
        if (this.listQuery.country == statNotCN) {
          this.radio1 = byIdOnOverseas;
        } else {
          this.radio1 = byID;
        }
      } else {
        this.radio1 = byName;
      }

    },
    pushCurrentPage() {
      this.$router.push({
        name: "statInfo",
        query: {listQuery: JSON.stringify(this.listQuery)},
      });
    },
    // Radio切换
    handleRadioChange() {
      this.listQuery = deepCopy(initListQuery);
      if (this.radio1 == byID) {
        this.listQuery.by = "id";
        this.listQuery.country = statCN;
      } else if (this.radio1 == byName) {
        this.listQuery.by = "name";
      } else if (this.radio1 == byIdOnOverseas) {
        this.listQuery.by = "id";
        this.listQuery.country = statNotCN;
      }
      this.listQuery.page = defaultPage;
      this.listQuery.pageSize = defaultSize;
      this.pushCurrentPage();
      this.key = Math.random();
    },
    // 页面条数变化
    handleSizeChange(size) {
      this.listQuery.pageSize = size;
      this.pushCurrentPage();
    },
    // 翻页
    handleCurrentChange(page) {
      this.listQuery.page = page;
      this.pushCurrentPage();
    },
    rowClick(row, column) {
      if (column.property == "tag") {
        return;
      }
      var param = "";
      if (this.radio1 == byID || this.radio1 == byIdOnOverseas) {
        param = JSON.stringify({
          id: row.id,
          page: defaultPage,
          pageSize: defaultSize,
        });
      } else {
        param = JSON.stringify({
          type: 1, // 1=all records
          name: row.name,
          page: defaultPage,
          pageSize: defaultSize,
        });
      }

      let routeUrl = this.$router.resolve({
        name: "manufacturerList",
        query: {
          switchStatus: false,
          listQuery: param,
        },
      });
      window.open(routeUrl.href, "_blank");
    },
    // 修改厂商tag
    modifyManufacturerTag(row) {
      updateManufacturerTag({id: row.id, tag: row.tag})
          .then(() => {
            this.getList();
          })
          .catch((err) => {
            console.log(err);
          });
    },
    // 下载量格式化
    downloadFormat(row, column, cellValue) {
      cellValue += "";
      if (!cellValue.includes(".")) cellValue += ".";
      return cellValue
          .replace(/(\d)(?=(\d{3})+\.)/g, function ($0, $1) {
            return $1 + ",";
          })
          .replace(/\.$/, "");
    },
    // 排序切换
    sortChange(obj) {
      var sortByMap = {
        total: "num",
        download: "download",
      };
      if (obj.order == "ascending") {
        this.listQuery.orderSort = "asc";
      } else if (obj.order == "descending") {
        this.listQuery.orderSort = "desc";
      } else {
        this.listQuery.sort = "";
      }
      if (obj.prop) {
        this.listQuery.orderField = sortByMap[obj.prop];
      } else {
        this.listQuery.orderField = "";
      }
      this.getList();
    },
  },
};
</script>
<style scoped>
.el-card {
  margin-top: 5px;
  margin-bottom: 5px;
}

.input-text :deep(.el-input__inner) {
  -webkit-appearance: none;
  background-color: #fff;
  background-image: none;
  border-radius: 4px;
  border: 0px;
  width: 100%;
}
</style>